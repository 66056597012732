.subNavItemWrapper {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--color-neutral-700);

  & .logoImgWrapper {
    height: 24px;
    margin-right: var(--spacing-2);
    position: relative;
    aspect-ratio: 1/1;
    width: unset;

    & .logoImage {
      width: 50px;
      height: 50px;
    }
  }

  &:hover {
    text-decoration: underline;
  }
}
