.subNavImgLink {
  display: flex;
  color: initial;
  flex-direction: row;
  align-items: flex-start;
  text-decoration: none;
  gap: var(--spacing-5);
  padding: var(--spacing-3);
  width: 100%;

  @media (--tablet-l) {
    padding: var(--spacing-3);
  }
  &:hover,
  &:active {
    background-color: var(--color-tarmac-grey-50);
  }

  & .subNavImg {
    position: relative;
    aspect-ratio: 1/1;
    height: 100px;
  }

  & .subNavImgContent {
    display: grid;
    gap: var(--spacing-1);

    & .imageLinkLabel {
      overflow: hidden;
      color: initial;
      margin: 0;
      text-decoration: none;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    }

    & .subNavImgDescription {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    & .subNavImgRating {
      display: flex;
      gap: var(--spacing-2);
      align-items: center;
    }

    & .subNavImgDate,
    & .subNavImgDescription,
    & .subNavImgRating {
      text-align: left;
    }
  }
}
