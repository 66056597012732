.subNavListWrapper {
  margin: 0;
  display: flex;
  padding: 0;
  flex-direction: column;
  list-style: none;

  & .imageList {
    width: auto;
    @media (--desktop-l) {
      width: 350px;
    }
  }

  & .standardList {
    padding-bottom: var(--spacing-4);
  }
}

.showAll {
  color: initial;
  display: flex;
  padding: 0;

  @media (--tablet-l) {
    padding-right: var(--spacing-12);
  }

  &.addExtraPadding {
    padding: var(--spacing-3);
  }
}
