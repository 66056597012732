.subNavGroup {
  display: flex;
  flex-direction: column;
  position: relative;
  color: var(--color-neutral-700);

  &::before {
    content: '';
    position: absolute;
    top: 63px;
    bottom: 0;
    right: 0px;
    width: 1px;
    background-color: var(--color-tarmac-grey-200);
    display: block;
  }

  & .subNavHeader {
    padding-bottom: var(--spacing-10);
  }

  &:last-child::before {
    display: none;
  }
}

.burgerMenuGrid {
  &.burgerMenuGridWithImage {
    gap: var(--spacing-17);
  }

  &.burgerMenuGridWithoutImage {
    gap: var(--spacing-12);
    @media (--desktop-l) {
      gap: var(--spacing-20);
    }
  }

  && {
    display: flex;
    width: inherit;
    padding: var(--spacing-12);

    @media (--desktop-l) {
      padding: var(--spacing-16);
    }
  }
}

.redirectUrlWrapper {
  display: flex;
  width: 100%;
  color: var(--color-primary-500);
  & .redirectUrl {
    display: flex;
    gap: var(--spacing-2);
    align-items: center;
  }
}

.subNavGroupWithoutImage ul li a {
  white-space: wrap;
  padding-right: var(--spacing-12);

  @media (--desktop-l) {
    padding-right: var(--spacing-20);
    white-space: nowrap;
  }
}

.subNavGroupWithImage ul {
  padding-right: var(--spacing-17);
}

.subNavGroupWithoutImage:last-of-type ul li a,
.subNavGroupWithImage:last-of-type ul {
  padding-right: 0;
}
