.titleWithIcon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 51px;
  padding: 0 var(--spacing-8);
}

.modalHeight {
  && {
    top: 64px;
    display: flex;
    transition: none;

    &.footerEnabled {
      top: 0;
      bottom: 52px;
    }

    @media (--tablet-l) {
      display: none;
    }
  }
}

.modalHeader {
  && {
    border-bottom: none;
    justify-content: start;
    flex-direction: row-reverse;
    align-items: center;
    gap: 10px;
    h6 {
      margin: 0;
    }
  }
}

.subNavList {
  border-top: 1px solid var(--color-tarmac-grey-300);

  && {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

.redirectUrl {
  border-top: 1px solid var(--color-tarmac-grey-300);
  padding: var(--spacing-5) 0;
  display: flex;
  color: var(--color-primary-500);
  gap: var(--spacing-2);
  align-items: center;
}

.closedAccordion {
  && {
    border: none;
  }
}
