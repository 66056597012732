.wrpr {
  color: var(--color-neutral-700);

  & span {
    display: flex;
    align-items: center;
    margin: 0 var(--spacing-3) var(--spacing-1) 0;

    & > svg {
      margin-right: var(--spacing-1);
      width: 102px;
      height: auto;
    }
  }

  & a {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: inherit;
    text-decoration: none;
  }

  &.darkMode {
    color: #fff;
  }
}

.shortLogo {
  margin-right: 10px;
  font-size: 26px;
}

.details {
  display: flex;
  gap: 10px;
  margin-bottom: 2px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.7;
}
