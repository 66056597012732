.navMenuWrapper {
  position: fixed;
  height: auto;
  overflow-y: hidden;
  bottom: 0;
  left: 0;
  top: 64px;
  right: 0;
  width: 100%;
  background-color: var(--header-nav-background-color);
  transform: translateX(110vw);

  &.footerEnabled {
    top: 0;
    bottom: 52px;
  }

  & .menuHeader {
    display: flex;
    height: 64px;
    flex-direction: column;
    justify-content: center;
    border-bottom: 1px solid var(--neutral-300-disabled, #b5b5b5);
    align-items: center;
    margin: 0 var(--spacing-5);
  }

  @media (--tablet-l) {
    display: none;
  }
}

.addTransition {
  transition: transform 300ms ease-in-out, background-color 300ms ease-in-out;
  background-color: var(--color-championship-white);
  transform: translateX(0);
  z-index: 100;
}

.navMenu {
  & .navList {
    list-style: none;
    padding: 14px 0;
    margin: 0;
    display: flex;
    flex-direction: column;
  }

  & .navItem {
    & .navLink {
      text-decoration: none;
      color: var(--color-neutral-700);
      height: 51px;
      display: flex;
      align-items: center;
      padding: 0 var(--spacing-8);
    }

    &:active {
      background-color: var(--color-neutral-50);
    }
  }
}

.languageDropdown {
  && {
    background-color: var(--color-tarmac-grey-50);
    cursor: pointer;
    position: absolute;
    bottom: 0;

    & input {
      padding-left: var(--spacing-1);
    }

    fieldset,
    ul {
      border: none !important;
    }
  }
}
